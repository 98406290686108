/* You can add global styles to this file, and also import other style files */
@import "../include.scss";

$body-colors: (
    //"background-body": #F5F5F5,
    "background-body": #FFF,
);

html {
    width: 100%;
    height: 100vh;

    body {
        margin: 0;
        background: map-get($body-colors,'background-body');
        font-family: 'Poppins', sans-serif;

      // For mobile menu
      &.menu-open {
        overflow-y: hidden;
      }

      .pac-container {
        z-index: 9999999999;
      }
    }

  body.modal-open {
    overflow: hidden;
  }
}
